document.addEventListener("DOMContentLoaded", function () {
	//поиск в шапке
	(function () {
		let headersearch = {};
		headersearch.wrapper = document.querySelector("header .search");
		headersearch.toggler = headersearch.wrapper.querySelector(".search-toggle");
		headersearch.form = headersearch.wrapper.querySelector(".seach-form");
		headersearch.button = headersearch.wrapper.querySelector("button");
		headersearch.input = headersearch.wrapper.querySelector("input");
		headersearch.close = headersearch.wrapper.querySelector(".close");

		headersearch.toggler.addEventListener("click", function () {
			headersearch.form.classList.toggle("show");
			headersearch.input.focus();
		});

		headersearch.close.addEventListener("click", function () {
			headersearch.form.classList.remove("show");
		});

		headersearch.button.addEventListener("click", function () {
			let input = headersearch.input;
			if (input.value.length == 0) {
				event.preventDefault();
				headersearch.form.classList.toggle("show");
			}
		});

		document.addEventListener("click", function () {
			let searchCollectionArr = Array.from(headersearch.wrapper.querySelectorAll(" * "));
			if (!searchCollectionArr.includes(event.target)) {
				headersearch.form.classList.remove("show");
			}
		});
	})();

	//страница поиска
	(function (searchResults) {
		if (searchResults) {
			searchResults.querySelector(" button").addEventListener("click", function () {
				let input = searchResults.querySelector(" input");
				if (input.value.length == 0) {
					event.preventDefault();
				}
			});
		}
	})(document.querySelector(".search-results"));

	//мобильная навигация
	if (window.innerWidth < 900) {
		let slideMenu = document.querySelector("header .slide-menu");
		document.querySelector("header .burger img").addEventListener("click", function () {
			slideMenu.classList.add("show");
		});

		slideMenu.querySelector(".close").addEventListener("click", function () {
			slideMenu.classList.remove("show");
		});
	}

	//попапы на детальной
	(function (smallHelloPopup, bigHelloPopup) {
		if (smallHelloPopup) {
			smallHelloPopup.addEventListener("click", function () {
				smallHelloPopup.classList.remove("show");
			});
		} else {
			return false;
		}

		// if (smallHelloPopup && bigHelloPopup) {
		// 	smallHelloPopup.addEventListener("click", function () {
		// 		bigHelloPopup.classList.add("show");
		// 		smallHelloPopup.classList.remove("show");
		// 	});

		// 	smallHelloPopup.querySelector(".close").addEventListener("click", function () {
		// 		smallHelloPopup.classList.remove("show");
		// 		event.stopPropagation();
		// 	});

		// 	bigHelloPopup.querySelector(".close").addEventListener("click", function () {
		// 		bigHelloPopup.classList.remove("show");
		// 	});
		// }
		// })(document.querySelector(".popup-hello-small"), document.querySelector(".popup-hello-big"));
	})(document.querySelector(".popup-hello-small"));

	(function (sharingElem) {
		if (sharingElem) {
			let drop = sharingElem.querySelector(".dropup");
			sharingElem.querySelector(".toggle").addEventListener("click", function () {
				drop.classList.toggle("show");
			});

			drop.querySelector(".close").addEventListener("click", function () {
				drop.classList.remove("show");
			});
			document.addEventListener("click", function () {
				let sharingCollectionArr = Array.from(sharingElem.querySelectorAll(" * "));
				if (!sharingCollectionArr.includes(event.target)) {
					drop.classList.remove("show");
				}
			});
		}
	})(document.querySelector(".stats .sharing"));
});
